<template>
  <div id="app">
    <div class="login flex-column-center">
      <h1 class="login-title">欢迎来到EOS</h1>
      <h3 class="login-title-two">请输入手机号登陆</h3>
      <div class="login-form flex-column-center">
        <div class="login-form-input">
          <Field  left-icon="manager" v-model="username" placeholder="请输入用户名" />
        </div>
        <div class="login-form-input">
          <Field type='password'  left-icon="lock" v-model="password"  />
        </div>
        <div class="login-form-btn" @click="login">
          {{ loading ? "正在登录中..." : "登录" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast,Icon, Field  } from "vant";
import { toAuth, getToken } from "../../utils/auth";
import { login, getTokenAndAccoun } from "@/api/user";
import { getInfoByAccount } from "./login";
import storage from "../../utils/storage";

export default {
  name: "login",
  data() {
    return {
      username: "Roma09",
      password: "admin123",
      wxUserId: "",
      loading: false,
    };
  },
  components:{Field},
  methods: {
    login() {
      if (this.loading) return;
      if (!this.username.trim()) {
        return Toast("用户名不能为空");
      }
      if (!this.password.trim()) {
        return Toast("密码不能为空");
      }

      const params = {
        uname: this.username,
        upass: this.password,
        wxUserid: this.wxUserId,
      };
      this.loading = true;
      const toast = Toast.loading({
        duration: 0,
        message: "登录中...",
        forbidClick: true,
      });
      login(params)
        .then((res) => {
          if (res.success) {
            const { account_no, token, userid } = res.data;
            storage.set("account_no", account_no);
            storage.set("token", token);
            storage.set("wxUserId", userid);
            return res.data;
          } else {
            return new Promise.reject(new Error("登录失败"));
          }
        })
        .then(({ account_no }) => getInfoByAccount(account_no))
        .then(() => {
          Toast("登录成功");
          this.$router.push({ path: "/workbench" });
        })
        .catch((err) => {
          if (err) {
            Toast(err.message);
          }
        })
        .finally(() => {
          this.loading = false;
          toast.clear();
        });
    },
    // 获取url中的参数
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
  },
  created() {
    const toast = Toast.loading({
      duration: 0,
      message: "Loading...",
      forbidClick: true,
    });

    const userId =
      this.getQueryVariable("userId") || storage.get("wxUserId") || "XiaoBai";
    // const userId = 'XiaoBai'

    if (userId) {
      this.wxUserId = userId;
      const isbinding = +this.getQueryVariable("isbinding");

      if (isbinding) {
        getTokenAndAccoun({ userId })
          .then((res) => getInfoByAccount(res.data.account_no))
          .then(() => {
            this.$router.push({ path: "/workbench" });
          })
          .catch((err) => {
            if (err) {
              Toast(err.message);
            }
          })
          .finally(() => {
            this.loading = false;
            toast.clear();
          });
      } else {
        toast.clear();
      }
    } else {
      toAuth();
    }
  },
  beforeDestroy() {
    location.reload();
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}
</style>
<style lang="less" scoped>
 /deep/.van-cell {
  background: #F5F5F5;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login {
  width: 100%;
  height: 60vh;
  overflow: hidden;
  background-image: url('../../assets/images/切图.png') ;
  background-size:60%;

  background-position: 400px 136px;
  background-repeat: no-repeat;




}

.login-title {
  // color: #ffffff;
  font-size: 50px;
  position: relative;
  left: -20%;
}
.login-title-two {
  width: 300px;
  height: 120px;
  background: rgba(0, 0, 0, 0);
  opacity: 1;
  color: #cecece;
  line-height: 110px;
  margin-right: 40%;
}

.login-form {
  width: 80%;
  margin: 20px auto;
}

.login-form-input {
  position: relative;
  width: 100%;
  margin: 15px 10px;

  input {
    width: 100%;
    height: 40px;
    border: none;
    background: transparent;
    color: #ffffff;
    outline: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid #fff;
  }
}

.login-form-tip {
  width: 100%;
  text-align: left;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.65);
}

.login-form-btn {
  margin: 50px 30px 0;
  padding: 0;
  line-height: 80px;
  width: 100%;
  text-align: center;
  color: #ffffff;
  user-select: none;
  cursor: pointer;
  background-color: #DA3738;
}
</style>
